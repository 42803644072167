.sidebar-container {
  overflow: scroll;
  height: 600px;
  padding: 30px 10px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.sidebar-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebar-container {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.sidebar-container .img-container {
  width: 100%;
  height: 120px;
  background-size: cover;
  background-origin: border-box;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.sidebar-container .img-container:hover {
  transform: scale(1.1);
  transform-origin: 50% 50%;
}

.sidebar-container .video-img {
  width: 100%;
  height: 240px;
  background-size: cover;
  background-origin: border-box;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.sidebar-container .video-img svg {
  fill: #ffffff;
  opacity: 0.75;
  font-size: 82px;
  transition: all 0.2s ease-in-out;
}

.sidebar-container .video-img:hover svg {
  opacity: 1;
}

.sidebar-container .video-container .video-description {
  justify-content: space-between;
  padding: 10px 30px;
  background: #ffffff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.MuiTabs-indicator {
  background-color: #1e3465 !important;
  height: 4px !important;
  border-radius: 1px !important;
}

.MuiTab-root {
  font-family: "Poppins", "sans-serif" !important;
  border-bottom: 1px solid #1e3465 !important;
  color: #1e3465 !important;
}

.Mui-selected {
  color: #1e3465 !important;
  font-weight: 700;
}

.main-img {
  width: 100%;
  height: 750px;
  background-size: contain;
  background-origin: border-box;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.2s ease-in-out;
}

.main-img svg {
  fill: #ffffff;
  opacity: 0.75;
  font-size: 120px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.main-img svg:hover {
  opacity: 1;
}

.inner-border {
  box-shadow: inset 0px 0px 0px 5px rgb(30, 52, 101);
  box-sizing: border-box;
}
