.dropdown {
    position: relative;
    margin: 0 10px;
}

.dropdown-container {
    position: absolute;
    background: #161944;
    top: 36px;
    left: 0;
    padding: 0 10px;
    border: 1px solid #03A398;
    border-top-color: transparent;
    width: calc(100% - 22px);
    transform: scaleY(0);
    transform-origin: top;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    z-index: 2;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.dropdown-container.active {
    opacity: 1;
    transform: scaleY(1);
}

.dropdown-container p {
    height: 36px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.dropdown-container p:hover {
    opacity: 0.8;
}


.dropdown-button {
    justify-content: space-between;
    padding: 0 10px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    height: 36px;
    border: 1px solid transparent;
    border-radius: 5px;
    z-index: 3;
}

.dropdown-button.active {
    background: #161944;
    border: 1px solid #03A398;
    border-bottom-color: #161944;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}


.dropdown-button:hover {
    opacity: 0.75;
}

.dropdown-button p {
    color: #ADB5BD;
}

.dropdown-button p.active{
    color: #FFFFFF;
}

.dropdown-button svg {
    color: #03A398;
    transition: all 0.3s ease-in-out;
}

.dropdown-button.active svg {
    transform: rotate(-180deg);
}


/* white */

.dropdown.white .dropdown-button {
    justify-content: space-between;
    padding: 0 10px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    height: 36px;
    border: 1px solid #CED4DA;
    border-radius: 5px;
    z-index: 3;
}

.dropdown.white .dropdown-button.active {
    background: #FFFFFF;
    border: 1px solid #CED4DA;
    border-bottom-color: #FFFFFF;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}


.dropdown.white .dropdown-button svg {
    color: #CED4DA;
    transition: all 0.3s ease-in-out;
}


.dropdown.white .dropdown-container {
    position: absolute;
    background: #FFFFFF;
    top: 36px;
    left: 0;
    padding: 0 10px;
    border: 1px solid #CED4DA;
    border-top-color: transparent;
    width: calc(100% - 22px);
    transform: scaleY(0);
    transform-origin: top;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    z-index: 2;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}


.dropdown.white .dropdown-container.active {
    opacity: 1;
    transform: scaleY(1);
}

.dropdown.white .dropdown-container p {
    height: 36px;
    display: flex;
    align-items: center;
    color: #000000;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}


.dropdown.white .dropdown-button p {
    color: #ADB5BD;
}

.dropdown.white .dropdown-button p.active{
    color: #000000;
}

.dropdown.white .react-calendar__month-view__weekdays {
    display: none !important;
}

.dropdown.white .react-calendar {
    border: none !important;
    font-family: 'Poppins', 'sans-serif' !important;
}
.dropdown.white .react-calendar__tile {
    color: #353535;
    font-family: 'Poppins', 'sans-serif' !important;
    font-size: 1.2em;
}

.dropdown.white .react-calendar__tile--now {
    background: none !important;
}

.dropdown.white .react-calendar__tile--now:hover {
    background: #e6e6e6 !important;
}

.dropdown.white .react-calendar__tile--active {
    background: none;
    color: #000000;
}

.dropdown.white .react-calendar__month-view__days__day--weekend.react-calendar__tile {
    color: #000000 ;
}
.dropdown.white .react-calendar__month-view__days__day--neighboringMonth.react-calendar__tile {
    color: #ADB5BD;
}
.dropdown.white .react-calendar .react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile {
    padding: .75em .5em !important;
}


/* blue */

.dropdown.blue .dropdown-button {
    justify-content: space-between;
    padding: 0 10px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    height: 36px;
    border: 1px solid #505174;
    background: #505174;
    border-radius: 5px;
    z-index: 3;
}

.dropdown.blue .dropdown-button.active {
    background: #505174;
    border: 1px solid #505174;
    border-bottom-color: #505174;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}


.dropdown.blue .dropdown-button svg {
    color: #ADB5BD;
    transition: all 0.3s ease-in-out;
}


.dropdown.blue .dropdown-container {
    position: absolute;
    background: #505174;
    top: 36px;
    left: 0;
    padding: 0 10px;
    border: 1px solid #505174;
    border-top-color: transparent;
    width: calc(100% - 22px);
    transform: scaleY(0);
    transform-origin: top;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    z-index: 2;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}


.dropdown.blue .dropdown-container.active {
    opacity: 1;
    transform: scaleY(1);
}

.dropdown.blue .dropdown-container p {
    height: 36px;
    display: flex;
    align-items: center;
    color: #000000;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}


.dropdown.blue .dropdown-button p {
    color: #FFFFFF;
    font-size: 0.75em;
}

.dropdown.blue .dropdown-button p.active{
    color: #FFFFFF;
}

.dropdown.blue .react-calendar__month-view__weekdays {
    display: none !important;
}

.dropdown.blue .react-calendar {
    border: none !important;
    background: transparent;
    font-family: 'Poppins', 'sans-serif' !important;
}
.dropdown.blue .react-calendar__tile {
    color: #FFFFFF;
    font-family: 'Poppins', 'sans-serif' !important;
    font-size: 0.75em;
}

.dropdown.blue .react-calendar__tile--now {
    background: none !important;
}

.dropdown.blue .react-calendar__tile {
    transition: all .2s ease-in-out;
}

.dropdown.blue .react-calendar__tile:hover {
    opacity: 0.65;
    background: none;
}

.dropdown.blue .react-calendar__tile--now:hover {
    opacity: 0.65;
}

.dropdown.blue .react-calendar__tile--active {
    background: none;
    color: #FFFFFF;
}

.dropdown.blue .react-calendar__month-view__days__day--weekend.react-calendar__tile {
    color: #FFFFFF ;
}
.dropdown.blue .react-calendar__month-view__days__day--neighboringMonth.react-calendar__tile {
    color: #ADB5BD;
}
.dropdown.blue .react-calendar .react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile {
    padding: .75em .5em !important;
}