.container {
  background: #f5faff;
  padding: 10px 3%;
}

.sidebar {
  background: #fff;
  border-radius: 10px;
  margin-bottom: 60px !important;
}

.sidebar-img {
  max-width: 100%;
  max-height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
  font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  color: #1e3465;
  font-family: Poppins;
}

.color-primary {
  color: #1e3465;
}

.bg-primary {
  background-color: #1e3465 !important;
}

a {
  text-decoration: none;
}

.text-center {
  text-align: center;
}

.main {
  padding-left: 20px;
}

.flex-standard {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-light {
  color: rgba(30, 52, 101, 0.65) !important;
}

.blue-button {
  position: relative;
  text-align: center;
  background: #1e3465;
  border-radius: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-top: 12px;
  margin-bottom: 12px;
  border: none;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.blue-button:hover {
  background: rgba(30, 52, 101, 0.9);
}

.blue-button-outlined {
  background: #fff;
  border-radius: 6px;
  margin-top: 12px;
  margin-bottom: 12px;
  border: 1px solid #1e3465;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.blue-button-outlined:hover {
  background: #f5faff;
}

.disable-button {
  position: relative;
  text-align: center;
  background: #ffffff;
  border-radius: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-top: 12px;
  margin-bottom: 12px;
  border: 1px solid #1e3465;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.cancel-button {
  background: #fff;
  border-radius: 6px;
  margin-top: 1em;
  margin-bottom: 1em;
  border: 0;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.cancel-button:hover {
  background: #f5faff;
}

.arrow-link {
  display: flex;
  color: #ffffff;
  font-size: 24px;
  background: #1e3465;
  padding: 5px 3px;
  border-radius: 10px;
  margin-left: 12px;
  align-items: center;
}

.main-content {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.paper {
  border: 1px solid rgba(30, 52, 101, 0.1);
  border-radius: 10px;
}

.MuiTableCell-root,
.MuiTableHead-root {
  border: none !important;
  padding: 10px 0 !important;
}

.MuiTableCell-root:last-child {
  padding-right: 20px !important;
}

.MuiTableCell-root:first-child {
  padding-left: 10px !important;
}

.MuiTable-fixedFooter {
  opacity: 0.65;
}

.row-hover:hover .MuiTableCell-body {
  opacity: 0.65;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.MuiTableCell-body {
  color: rgba(30, 52, 101, 1) !important;
}

.MuiGrid-root:focus {
  outline: none;
}

.MuiTableHead-root .MuiTableCell-root {
  font-size: 12px;
  vertical-align: bottom;
}

.MuiTimelineContent-root {
  padding-top: 0 !important;
}

.MuiTimelineOppositeContent-root {
  padding-top: 0 !important;
}

.MuiTimelineConnector-root {
  width: 4px !important;
  background-color: rgba(30, 52, 101, 1) !important;
}

.MuiTimelineDot-root {
  padding: 10px !important;
  margin: 0 !important;
}

.MuiTimelineDot-defaultGrey {
  border-style: solid;
  border-width: 2px;
  background-color: rgba(30, 52, 101, 1) !important;
  border-color: rgba(30, 52, 101, 1) !important;
  box-shadow: none !important;
}

.MuiTimelineDot-outlinedGrey {
  border-color: rgba(30, 52, 101, 1) !important;
  box-shadow: none !important;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(30, 52, 101, 0.75) rgba(30, 52, 101, 0.1);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: rgba(30, 52, 101, 0.1);
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(30, 52, 101, 0.75);
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: rgba(30, 52, 101, 1);
  border-radius: 20px;
}

.finance-donut {
  cursor: pointer;
  padding: 20px;
  transition: all 0.2s ease-in-out;
}

.finance-donut:hover {
  background-color: rgba(30, 52, 101, 0.1);
}

.MuiAccordion-root {
  text-align: center;
}

.MuiAccordionSummary-expandIcon {
  margin-left: auto !important;
}

.MuiAccordionSummary-content {
  position: absolute;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.MuiAccordionDetails-root {
  padding: 0px !important;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.topbar {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  padding: 10px 2%;
}

.footer {
  position: absolute;
  bottom: 0;
  height: 30px;
  width: 100%;
  background-color: #f3f0f0;
}

.pointer {
  cursor: pointer;
}

/* only run on Safari */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    /* fixed topbar not showing in Safari */
    .container {
      position: static !important;
      margin-top: 50px;
    }
    .topbar {
      background-color: white;
      z-index: 99;
    }
  }
}

.flex {
  display: flex;
}

.flex.row {
  flex-direction: row;
}

.flex.row.center {
  justify-content: center;
}

.flex.row.left {
  justify-content: flex-start;
}

.flex.row.right {
  justify-content: flex-end;
}

.flex.row.middle {
  align-items: center;
}

.flex.row.top {
  align-items: flex-start;
}

.flex.row.bottom {
  align-items: flex-end;
}

.flex.column {
  flex-direction: column;
}

.flex.column.middle {
  justify-content: center;
}

.flex.column.top {
  justify-content: flex-start;
}

.flex.column.bottom {
  justify-content: flex-end;
}

.flex.space-between{
  justify-content: space-between;
}

.flex.column.center {
  align-items: center;
}

.flex.column.left {
  align-items: flex-start;
}

.flex.column.right {
  align-items: flex-end;
}

.custom-form-table > tr > td {
  padding: 5px;
}
