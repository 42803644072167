.draw-image-container {
    position: relative;
}

.draw-image-container.active {
    cursor: crosshair;
}

.fabric {
    width: 100%;
    height: 750px;
}

.draw-image-box {
    top: 0;
    bottom: 0;
    left: 0; 
    right: 0; 
    margin: auto; 
    position: absolute;
    border: 3px solid #03A398;
    height: 150px;
    width: 150px;
}

.a-form-container {
    background: #161944;
    width: 250px;
    opacity: 0.9;
}

.a-form-container > .title {
    background: #141E48;
    padding: 10px 20px;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.35);
    text-transform: uppercase;
    color: #3BAFDA;
    font-size: 0.8em;
}

.a-form-container > .data-container {
    padding: 10px 20px;
}

.data-container .label {
    color: #ADB5BD;
    font-size: 0.7em;
    transition: all .2s ease-in-out;
}

.data-container .label.hover:hover {
    opacity: 0.75;
    cursor: pointer;
}


.data-container .data {
    color: #FFFFFF;
    font-size: 0.8em;
}

.data-container .MuiOutlinedInput-root{
    color: #FFFFFF;
    font-family: 'Poppins';
    font-size: 0.7em;
    background: rgba(255,255,255,0.2);
}
.data-container.single-line-input .MuiOutlinedInput-input{
    padding-top: 10px;
    padding-bottom: 10px;
}

.data-container .MuiOutlinedInput-notchedOutline {
    border: none;
}

.data-container .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none;
}

.data-container .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: none;
}

.data-container .a-checkbox {
    height: 24px;
    width: 24px;
    border: 1px solid #FFFFFF;
    border-radius: 100%;
    cursor: pointer;

}

.data-container .a-checkbox .a-checkbox-inner {
    height: 18px;
    width: 18px;
    border-radius: 100%;
    transform: scale(0);
    transition: all .2s cubic-bezier(0.075, 0.82, 0.165, 1);
}


.data-container .a-checkbox.active .a-checkbox-inner {
    transform: scale(1);
}

.data-container .a-checkbox:hover .a-checkbox-inner {
    transform: scale(1);
}