
  
  
  .cctvTabButton {
    padding: 5px;
    border: 1px solid rgba(30, 52, 101, 1);
    color: rgba(30, 52, 101, 0.5);
    border-radius: 5px;
    text-align: center;
    font-size: 12;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  .cctvTabButton:hover {
      background: rgba(30, 52, 101, 1);
      color: "#FFFFFF"
  }

  .cctvCopyButton {
    padding: 5px;
    border: 1px solid rgba(30, 52, 101, 1);
    background-color: #1e3465;
    border-radius: 5px;
    text-align: center;
    font-size: 12;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  .cctvCopyButton:hover {
      background: rgb(59, 80, 131);
      color: "#FFFFFF"
  }

.cctvTabButtonText {
    color: rgba(30, 52, 101, 1);
}
.cctvTabButton:hover .cctvTabButtonText {
  color: #FFFFFF !important;
}

.cctvTabButtonSVG {
    fill: rgba(30, 52, 101, 1);
}
.cctvTabButton:hover .cctvTabButtonSVG {
  fill: #FFFFFF !important;
}

  .cctvTabContent {
    text-align: center;
    display: block;
  }

  .cctvTabImage {
      border-radius: 10px;
      width: 100%;
  }