.filter {
    position: relative;
}

.filter-container {
    position: absolute;
    background: #161944;
    top: 38px;
    right: -10px;
    padding: 5px 15px 20px 15px;
    margin: 0 10px;
    border: 1px solid #03A398;
    transform: scaleY(0);
    width: 350px;
    transform-origin: top;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    z-index: 2;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.filter-container.active {
    opacity: 1;
    transform: scaleY(1);
}

.filter-container p {
    height: 36px;
    font-weight: 700;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.filter-container p:hover {
    opacity: 0.8;
}


.filter-button {
    position: relative;
    padding: 10px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    border: 1px solid transparent;
    z-index: 3;
}

.filter-button.active {
    background: #161944;
    border: 1px solid #03A398;
    border-bottom-color: #161944;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.filter-button svg{    
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.filter-button:hover svg{
    opacity: 0.75;
}


.filter-input .MuiInput-root {
    color: #FFFFFF;
    font-family: 'Poppins';
    font-size: 14px;
}


.filter-input .MuiInput-root::before {
    border-bottom-color: #9f9f9f;
    border-width: 2px;
    opacity: 0.65;
    transition: all .2s ease-in-out;
}


.filter-input .MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom-color: #9f9f9f;
    opacity: 1;
}


.filter-input .MuiInput-root::after {
    border-bottom-color: #03A398;
}

.filter-input .MuiAutocomplete-tag {
    margin: 0;
    margin-right: 3px;
    margin-bottom: 3px;
}

.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input {
    padding: 8px 4px;
}
.filter-input .MuiAutocomplete-endAdornment {
    top: calc(50% - 20px);
}

.filter-input .MuiChip-root {
    height: 24px;
    border-radius: 2px;
    border: 1px solid #03A398;
    background-color: transparent;
    color: #FFFFFF;
    font-family: 'Poppins', 'sans-serif';
    font-weight: 600;
}

.filter-input .MuiChip-root .MuiChip-label {
    padding-left: 5px;
    padding-right: 5px;
}

.filter-input .MuiChip-root .MuiChip-deleteIcon {
    margin: 0 5px;
    color: rgba(255,255,255,0.65);
    width: 14px;
    height: 14px;
}

.filter-input .MuiAutocomplete-popupIndicator {
    color: rgba(255,255,255,0.65);
}

.MuiAutocomplete-paper {
    background: #161944 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border: 1px solid #03A398 !important;
    font-family: 'Poppins', 'sans-serif' !important;
    color: #FFFFFF !important;
}
.MuiAutocomplete-paper .MuiAutocomplete-option[data-focus="true"] {
    background: transparent;
    opacity: 0.75;
    transition: all .2s ease-in-out;
}

.MuiAutocomplete-paper .MuiAutocomplete-noOptions {
    color: rgba(255,255,255,0.65);
}