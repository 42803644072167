.react-calendar {
  padding: 10px 20px;
  width: 100%;
  height: 450px;
  border: 1px solid rgba(30, 52, 101, 0.1);
  border-radius: 10px;
}
.react-calendar__month-view__weekdays {
  font-weight: 400;
  opacity: 0.65;
  font-size: 16px;
  color: rgba(30, 52, 101, 0.65);
}

/* .react-calendar__month-view__days__day--weekend {
    background-color: whitesmoke;
    font-weight: 400 !important;
} */

.react-calendar .react-calendar__tile--active:enabled {
  background: #e6e6e6;
}

.react-calendar__tile--now {
  background: none !important;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:focus {
  background: #fafafa !important ;
}

.react-calendar__tile--now::after {
  content: '\00000A•';
  color: rgba(30, 52, 101, 1);
  font-weight: bold;
}

.react-calendar .react-calendar__month-view__days__day--neighboringMonth {
  color: rgba(30, 52, 101, 0.45);
}

.react-calendar button {
  font-size: 18px;
  font-weight: 600;
  color: rgba(30, 52, 101, 1);
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.react-calendar__navigation__label > span {
  font-family: Poppins;
  font-size: 20px;
}
